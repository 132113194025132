import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  providers:[AuthService]
})
export class NavigationComponent implements OnInit {
      public isLogged = false;
      public user$ : Observable<any> = this.authSvc.afAuth.user;
      
  constructor( private authSvc:AuthService , private router:Router) { }

  async ngOnInit()  {
     await this.authSvc.getCurrentUser()
    
  }


async onLogout()
{
  try {
    await this.authSvc.logout()
    this.router.navigate(['/login'])
  } catch (error) {
    console.log(error)
  }
  

}

}
