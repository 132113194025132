
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#"><img src="../../../assets/logo-nav.svg" width="100" alt=""></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarColor02">
     
      <ul class="navbar-nav ml-auto" *ngIf="user$ | async as user;else login">
        <li class="nav-item " *ngIf="!user">
          <a class="nav-link" routerLink="/login">Login<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" *ngIf="user">
          <a class="nav-link logout" (click)="onLogout()">Logout</a>
        </li>

        <li class="nav-item" *ngIf="user">
          <a class="nav-link" >{{user.email}}</a>
        </li>
      </ul>
      

      <ng-template #login>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item " *ngIf="!user">
            <a class="nav-link" routerLink="/login">Login<span class="sr-only">(current)</span></a>
          </li>
          </ul>
      </ng-template>
     
    </div>
  </nav>



  